import { ref, onMounted } from '@vue/composition-api'
import footerDataTables from '@/fake-db/footerDataTables.json'
import { api } from '@/services/api'
import store from "@/store";
import XLSX from "xlsx";
import { removeComma } from "@/services/filters";

export default function useProduct() {
    const textSearch = ref('')
    const headers = ref([
        {
            text: '#',
            value: 'product_id',
            width: '50',
        },
        {
            text: 'รหัสสินค้า',
            value: 'product_code',
            width: '120',
            align: 'center',
        },
        {
            text: 'รูปสินค้า',
            value: 'product_image',
            width: '110',
            align: 'center',
        },
        {
            text: 'ชื่อสินค้า',
            value: 'product_name',
            width: '200',
        },
        {
            text: 'หมวดหมู่สินค้า',
            value: 'product_type_name',
            width: '150',
        },
        {
            text: 'น้ำหนัก',
            value: 'product_weight',
            width: '100',
            align: 'end',
        },
        {
            text: 'หน่วย',
            value: 'product_unit',
            width: '100',
        },
        {
            text: 'สถานะ',
            value: 'product_active',
            width: '100',
        },
        {
            text: 'ตัวเลือก',
            value: 'actions',
            width: '200',
            align: 'center fixed',
        },
    ])
    const itemsPerPage = ref(10)
    const status = ref('1')
    const dataList = ref([])
    const loading = ref(false)
    const isUpdateStatus = ref(false)
    const dataEdit = ref({})
    const footer = ref(footerDataTables)
    const fileImportTracking = ref(null)
    const isImport = ref(false)
    const isEdit = ref(false)
    const importLoading = ref(false)
    const isAdd = ref(false)
    const isShowDetail = ref(false)
    const exportLoading = ref(false)
    const isExport = ref(false)
    const XLSX = require('xlsx')
    const changeStatus = (id) => {
        status.value = id
        getProducts()
    }

    const getProducts = () => {
        loading.value = true
        api.get({
            path: '/center/products',
            param: `?search=${ textSearch.value }&active=${ status.value }`,
        }).then(({ data }) => {
            dataList.value = data
            loading.value = false
        }).catch(error => {
            console.log('error :', error)
            loading.value = false
        })
    }

    onMounted(() => {
        getProducts()
    })

    const importTracking = () => {
        importLoading.value = true
        let formData = new FormData()
        formData.append('productX', fileImportTracking.value)
        api.post({
            path: '/center/productImport',
            body: formData,
        }).then(res => {
            store.commit('app/ALERT', {
                message: res.message,
                color: res.response ? 'success' : 'error',
            })
            if (res.response) {
                isImport.value = false
            }
            importLoading.value = false
            getProducts()
        }).catch(error => {
            importLoading.value = false
            console.log('error :', error)
        })
    }

    const mapData = (data) => {
        const x = data.map((item, index) => {
            delete item.product_id
            delete item.product_image
            delete item.product_active
            delete item.product_create
            delete item.shop_name
            delete item.product_update
            delete item.product_type_id

            return {
                number: index + 1,

                ...item,
            }
        })
        return x
    }

    const exportExcel = async () => {
        exportLoading.value = true
        const fileName = `Product.xlsx`
        const Heading = [
            [
                '#',
                `รหัสสินค้า`,
                'ชื่อสินค้า',
                'รายละเอียด',
                'หมวดหมู่สินค้า',
                `น้ำหนัก`,
                'หน่วย',
            ],
        ]
        const ws = XLSX.utils.aoa_to_sheet(Heading)
        const myData = mapData(dataList.value)
        const dataExport = await removeComma(JSON.parse(JSON.stringify(myData)))
        XLSX.utils.sheet_add_json(ws, dataExport, {
            header: [
                'number',
                'product_code',
                'product_name',
                'product_description',
                'product_type_name',
                'product_weight',
                'product_unit',
            ],
            skipHeader: true,
            origin: -1,
        })
        const wb = XLSX.utils.book_new()
        const wscols = [
            { wch: 26 },
            { wch: 26 },
            { wch: 26 },
            { wch: 50 },
            { wch: 26 },
            { wch: 26 },
            { wch: 26 },
            { wch: 26 },
            { wch: 26 },
            { wch: 26 },
        ]
        ws['!cols'] = wscols
        XLSX.utils.book_append_sheet(
            wb,
            ws,
            `รายการสินค้า`,
        )

        XLSX.writeFile(wb, fileName)
        setTimeout(() => {
            exportLoading.value = false
        }, 1500)
    }

    return {
        exportExcel,
        exportLoading,
        textSearch,
        headers,
        itemsPerPage,
        isAdd,
        isShowDetail,
        isEdit,
        isExport,
        dataEdit,
        footer,
        importLoading,
        isUpdateStatus,
        status,
        importTracking,
        dataList,
        loading,
        fileImportTracking,
        isImport,
        changeStatus,
        getProducts,
    }
}
