var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(" สินค้า ")]),_c('v-spacer'),_c('v-btn',{staticClass:"me-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.isAdd = true}}},[_vm._v("เพิ่มสินค้า")]),_c('v-btn',{staticClass:"me-2",attrs:{"color":"info"},on:{"click":function($event){_vm.fileImportTracking = null;_vm.isImport = true}}},[_vm._v("Import Product")]),_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.exportExcel}},[_vm._v("Export Product")])],1),_c('v-row',{staticClass:"px-2 pb-2"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"filled":"","label":"ค้นหาสินค้า","dense":"","hide-details":"","placeholder":"คำค้นหา"},on:{"input":_vm.getProducts},model:{value:(_vm.textSearch),callback:function ($$v) {_vm.textSearch=$$v},expression:"textSearch"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('FilterStatus',{on:{"selection":_vm.changeStatus}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataList,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"no-data-text":"ไม่มีข้อมูล !","loading-text":"กำลังโหลดข้อมูล...","footer-props":_vm.footer,"no-results-text":"ไม่มีข้อมูล !"},scopedSlots:_vm._u([{key:"item.product_id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.product_image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"elevation-4 rounded-lg my-1",attrs:{"src":item.product_image,"width":"80","height":"80"}})]}},{key:"item.product_active",fn:function(ref){
var item = ref.item;
return [_c('StatusBlock',{attrs:{"status":String(item.product_active)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.dataEdit = item;_vm.isShowDetail = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTextBoxCheck))])],1)]}}],null,true)},[_c('span',[_vm._v("รายละเอียด")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.dataEdit = item;_vm.isEdit = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPencilOutline))])],1)]}}],null,true)},[_c('span',[_vm._v("แก้ไข")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.dataEdit = item;_vm.isUpdateStatus = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.product_active == 1 ? _vm.mdiDeleteOutline : _vm.mdiCheck))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.product_active == 1 ? "ระงับ" : "ปกติ"))])])]}}],null,true)})],1),_c('AddNewProduct',{on:{"onAdd":_vm.getProducts},model:{value:(_vm.isAdd),callback:function ($$v) {_vm.isAdd=$$v},expression:"isAdd"}}),_c('EditProduct',{attrs:{"data-edit-product":_vm.dataEdit},on:{"onUpdate":_vm.getProducts},model:{value:(_vm.isEdit),callback:function ($$v) {_vm.isEdit=$$v},expression:"isEdit"}}),_c('SuspendProduct',{attrs:{"data-update":_vm.dataEdit},on:{"onUpdate":_vm.getProducts},model:{value:(_vm.isUpdateStatus),callback:function ($$v) {_vm.isUpdateStatus=$$v},expression:"isUpdateStatus"}}),_c('ProductDetail',{attrs:{"data-detail":_vm.dataEdit},model:{value:(_vm.isShowDetail),callback:function ($$v) {_vm.isShowDetail=$$v},expression:"isShowDetail"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.isImport),callback:function ($$v) {_vm.isImport=$$v},expression:"isImport"}},[_c('v-card',[_c('v-card-title',[_vm._v(" นำเข้าไฟล์ สินค้า "),_c('v-spacer'),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{attrs:{"href":_vm.formImport}},[_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary","x-small":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s(_vm.mdiCloudDownload))])],1)],1)]}}])},[_c('span',[_vm._v("ดาวน์โหลดไฟล์ตัวอย่าง")])])],1),_c('v-divider'),_c('v-row',{staticClass:"mx-2 my-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"label":"เลือกไฟล์","filled":"","hide-details":"","accept":".xlsx, .xls","prepend-icon":_vm.mdiPaperclip},model:{value:(_vm.fileImportTracking),callback:function ($$v) {_vm.fileImportTracking=$$v},expression:"fileImportTracking"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.importLoading,"disabled":_vm.importLoading || !_vm.fileImportTracking},on:{"click":_vm.importTracking}},[_vm._v("ยืนยัน ")]),_c('v-btn',{attrs:{"color":"secondary","outlined":"","loading":_vm.importLoading,"disabled":_vm.importLoading},on:{"click":function($event){_vm.isImport = false}}},[_vm._v("ยกเลิก ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }