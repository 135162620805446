<template>
  <div>
    <v-card>
      <v-card-title>
        <span>
          สินค้า
        </span>
        <v-spacer></v-spacer>
        <v-btn color='primary' class="me-2" @click='isAdd = true'>เพิ่มสินค้า</v-btn>
        <v-btn color='info' class="me-2" @click="fileImportTracking = null;isImport = true">Import Product</v-btn>
        <v-btn color='accent' @click="exportExcel">Export Product</v-btn>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        @input='getProducts'
                        label='ค้นหาสินค้า'
                        dense
                        hide-details
                        placeholder='คำค้นหา'></v-text-field>
        </v-col>
        <v-col cols='12' md='6' lg='4'>
          <FilterStatus @selection='changeStatus'/>
        </v-col>
      </v-row>
      <v-data-table
          :headers='headers'
          :items='dataList'
          :items-per-page='itemsPerPage'
          :loading='loading'
          no-data-text='ไม่มีข้อมูล !'
          loading-text='กำลังโหลดข้อมูล...'
          :footer-props='footer'
          no-results-text='ไม่มีข้อมูล !'
          class='elevation-1'
      >
        <template #[`item.product_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template #[`item.product_image`]='{ item }'>
          <v-img :src='item.product_image' class='elevation-4 rounded-lg my-1' width='80'
                 height='80'>

          </v-img>
        </template>
        <template #[`item.product_active`]='{ item }'>
          <StatusBlock :status='String(item.product_active)'/>
        </template>
        <template #[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='dataEdit = item;isShowDetail = true'
                  v-on='on'
              >
                <v-icon>{{ mdiTextBoxCheck }}</v-icon>
              </v-btn>
            </template>
            <span>รายละเอียด</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='dataEdit = item;isEdit = true'
                  v-on='on'
              >
                <v-icon>{{ mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>แก้ไข</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='dataEdit = item;isUpdateStatus = true'
                  v-on='on'
              >
                <v-icon>{{ item.product_active == 1 ? mdiDeleteOutline : mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.product_active == 1 ? `ระงับ` : `ปกติ` }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewProduct v-model='isAdd' @onAdd='getProducts'/>
    <EditProduct v-model='isEdit' @onUpdate='getProducts' :data-edit-product='dataEdit'/>
    <SuspendProduct v-model='isUpdateStatus' :data-update='dataEdit' @onUpdate='getProducts'/>
    <ProductDetail v-model='isShowDetail' :data-detail='dataEdit'/>
    <v-dialog v-model="isImport" persistent max-width="400">
      <v-card>
        <v-card-title>
          นำเข้าไฟล์ สินค้า
          <v-spacer></v-spacer>
          <v-tooltip
              color="#212121"
              top
          >
            <template v-slot:activator="{ on, attrs }">
              <a :href="formImport">
                <v-btn
                    color="primary"
                    class="ma-2"
                    v-bind="attrs"
                    x-small
                    fab
                    v-on="on"
                >
                  <v-icon dark>{{ mdiCloudDownload }}</v-icon>
                </v-btn>
              </a>
            </template>
            <span>ดาวน์โหลดไฟล์ตัวอย่าง</span>
          </v-tooltip>
        </v-card-title>
        <v-divider></v-divider>
        <v-row class="mx-2 my-2">
          <v-col cols="12">
            <v-file-input
                label="เลือกไฟล์"
                filled
                hide-details
                accept=".xlsx, .xls"
                v-model="fileImportTracking"
                :prepend-icon="mdiPaperclip"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="importTracking" :loading="importLoading"
                 :disabled="importLoading || !fileImportTracking">ยืนยัน
          </v-btn>
          <v-btn color="secondary" outlined :loading="importLoading" :disabled="importLoading"
                 @click="isImport = false">ยกเลิก
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import useProduct from './useProduct'
import FilterStatus from '@/components/FilterStatus'
import StatusBlock from '@/components/StatusBlock'
import { mdiPencilOutline, mdiCloudDownload, mdiDeleteOutline, mdiCheck, mdiTextBoxCheck, mdiPaperclip } from '@mdi/js'
import AddNewProduct from '@/views/center/product/AddNewProduct'
import EditProduct from '@/views/center/product/EditProduct'
import SuspendProduct from '@/views/center/product/SuspendProduct'
import ProductDetail from '@/views/center/product/ProductDetail'
import formImport from '@/assets/download_files/product.xlsx'

export default {
  props: {},
  components: {
    ProductDetail,
    SuspendProduct,
    AddNewProduct,
    FilterStatus,
    StatusBlock,
    EditProduct,
  },
  setup(_, { root, emit }) {
    return {
      ...useProduct(),
      mdiCheck,
      mdiTextBoxCheck,
      mdiPaperclip,
      formImport,
      mdiPencilOutline, mdiCloudDownload,
      mdiDeleteOutline,
    }
  },

}
</script>

<style scoped>

</style>
